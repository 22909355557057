<template>
    <div >
        <template v-for=" item in menus " >
            <template v-if="!item.hide">
                <template v-if="!item.noMenu">
                    <Submenu v-if="item.children" :name="item.name">
                        <template #title>   <!--具名插槽，把该template中的内容插入到title的插槽中-->
                            <!--
                            <Icon type="ios-analytics" />
                            -->
                            <div @click="subMenuClick(item.name)">
                                {{ item.meta.title }}
                            </div>
                            
                        </template>

                        <template v-for="child in item.children" :key="child.name">
                            <template v-if="!child.meta.hide">
                                <MenuItem :name="child.name" >
                                    <router-link :to="child.path" >
                                        <span v-if="child.meta && child.meta.title" slot="title" style="color: white;">{{ child.meta.title }}</span>
                                    </router-link>
                                </MenuItem>
                            </template>
                        </template>

                    </Submenu>
                </template>
                <MenuItem v-else :name="item.name" >
                    <router-link :to="item.path">
                        <span v-if="item.meta && item.meta.title" slot="title" style="color: white;">{{ item.meta.title }}</span>
                    </router-link>
                </MenuItem>
            </template>
        </template>
    </div>
</template>

<script>
    export default{
        name:'MenuSub',
        props: ['menus'],
        data(){
            return {}
        },
        created(){
            
        },
        methods:{
            isItemDefined(item) {
                return item !== undefined && item !== null;
                },
                subMenuClick(name1){
                    //console.info(name+"=================");
                    this.$emit('on-select',{name:name1})
                }
        }
    }
</script>
