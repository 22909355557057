import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUIPlus from "view-ui-plus"
import "view-ui-plus/dist/styles/viewuiplus.css"    
import '@/assets/iconfont/iconfont.css'
import '@/assets/iconfont/iconfont.js'


    window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = true


    const vue= createApp(App)
    vue.use(store)
    vue.use(router)
    vue.use(ViewUIPlus)
    vue.mount('#app')

    /*
    window.onerror=function (message,source,lineno,colno,error){

        return true;//阻止浏览器默认的错误提示框
    }
        */