
const routerMaps= [
    {
      path:'/',
      name:'login',
      component:()=>import('@/views/LoginView.vue'),
      meta:{
        keepAliva:false,
        title: "login",
        icon: ''
      },
      noMenu:true,
      hide:true
    },
    {
      path:'/result',
      name:'result',
      component:()=>import('@/views/ResultView.vue'),
      meta:{
        keepAliva:false,
        title: "result",
        icon: ''
      },
      noMenu:true,
      hide:true
    },
    {
      path:'/error',
      name:'error',
      component:()=>import('@/views/ErrorView.vue'),
      meta:{
        keepAliva:false,
        title: "error",
        icon: ''
      },
      noMenu:true,
      hide:true
    },
    {
      path:'/user/detail',
      name:'user_detail',
      component:()=>import('@/views/UserManager/UserDetailView.vue'),
      meta:{
        keepAliva:true,
        title: "用户详情",
        icon: '',
        requireAuth:true
      },
      noMenu:true,
      hide:true
    },

    //首页
    {
      path:'/home',
      name:'home',
      component:()=>import('@/views/HomeView.vue'),
      meta:{
        keepAliva:true,
        title: "首页",
        icon: '',
        requireAuth:true
      },
      noMenu:true
    },
    //用户列表
    {
      path:'/user',
      name:'user_root',
      component:null,
      meta:{
        keepAliva:true,
        title: "用户管理",
        icon: '',
        requireAuth:true
      },
      children:[
        {
          path:'/user/all',
          name:'user_list',
          component:()=>import('@/views/UserManager/UserListView.vue'),
          meta:{
            title:'用户列表',
            icon:'',
            requireAuth:true
          }
        },
        {
          path:'/user/add_user',
          name:'add_user',
          component:()=>import('@/views/UserManager/AddUserView.vue'),
          meta:{
            title:'添加用户',
            icon:'',
            keepAliva:true,
            isCache:true,
            requireAuth:true
          }
        },
        {
          path:'/user/add_driver',
          name:'user_add_driver',
          component:()=>import('@/views/UserManager/AddDriverView.vue'),
          meta:{
            title:'认证司机信息',
          icon:'',
          keepAliva:true,
          isCache:true,
          requireAuth:true
        }
        },
        {
          path:'/user/add_company',
          name:'user_add_company',
          component:()=>import('@/views/UserManager/AddCompanyView.vue'),
          meta:{
            title:'认证货主信息',
            icon:'',
            keepAliva:true,
            isCache:true,
            requireAuth:true
            }
        }
      ]
    },
    //车辆列表
    {
      path:'/vehicle',
      name:'vehicle_root',
      component:null,
      meta:{
        keepAliva:true,
        title: "车辆管理",
        icon: '',
        requireAuth:true
      },
      children:[
        {
          path:'/vehicle/all',
          name:'vehicle_all',
          component:()=>import('@/views/VehicleManager/VehicleListView.vue'),
          meta:{
            keepAliva:true,
            title: "车辆列表",
            icon: '',
            requireAuth:true
          }   
        },
        {
          path:'/vehicle/add',
          name:'vehicle_add',
          component:()=>import('@/views/VehicleManager/AddVehicleView.vue'),
          meta:{
            KeepAliva:true,
            title: "添加车辆",
            icon: '',
            isCache:true,
            requireAuth:true
          },
    
        },
        {
          path:'/vehicle/detail',
          name:'vehicle_detail',
          component:()=>import('@/views/VehicleManager/VehicleDetailView.vue'),
          meta:{
            keepAliva:true,
            title: "车辆详情",
            icon: '',
            hide:true,
            requireAuth:true
          },
        },        
        {
          path:'/vehicle/auth',
          name:'vehicle_auth',
          component:()=>import('@/views/VehicleManager/VehicleAuthView.vue'),
          meta:{
            keepAliva:true,
            title: "车辆审核",
            icon: '',
            hide:true,
            requireAuth:true
          },
        },        
    
      ]
    },
    {
      path:'/order',
      name:'order',
      component:null,
      meta:{
        keepAliva:true,
        title: "订单管理",
        icon: '',
        requireAuth:true
      },
      children:[
        {
          path:'/order/list',
          name:'order_list',
          component:()=>import('@/views/OrderManager/OrderListView.vue'),
          meta:{
            keepAliva:true,
            title:'订单列表',
            icon:'',
            requireAuth:true
          }
        },
        {
          path:'/order/add_new',
          name:'order_add_new',
          component:()=>import('@/views/OrderManager/CreateOrderView.vue'),
          meta:{
            keepAliva:true,
            title:'创建订单',
            icon:'',
            isCache:true,
            requireAuth:true
          }
        },
        {
          path:'/order/detail/:id',
          name:'order_detail',
          component:()=>import('@/views/OrderManager/OrderDetailView.vue'),
          meta:{
            keepAliva:true,
            title:'订单详情',
            icon:'',
            hide:true,
            requireAuth:true
          }
        },
        {
          path:'/order/edit/:id',
          name:'order_edit',
          component:()=>import('@/views/OrderManager/OrderEditView.vue'),
          meta:{
            keepAliva:true,
            title:'编辑订单',
            icon:'',
            hide:true,
            isCache:true,
            requireAuth:true
          }
        },
        {
          path:'/order/auth',
          name:'order_auth',
          component:()=>import('@/views/OrderManager/OrderAuthView.vue'),
          meta:{
            keepAliva:true,
            title:'订单审核',
            icon:'',
            hide:true,
            requireAuth:true
          }
        },
      ]
    },
    {
      path:'/waybill',
      name:'waybill',
      component:null,
      meta:{
        keepAliva:true,
        title: "运单管理",
        icon: '',
        requireAuth:true
      },
      children:[
        {
          path:'/waybill/list',
          name:'waybill_list',
          component:()=>import('@/views/WaybillManager/WaybillListView.vue'),
          meta:{
            keepAliva:true,
            title:'运单列表',
            icon:'',
            requireAuth:true
          }  
        },
        {
          path:'/waybill/detail/:id',
          name:'waybill_detail',
          component:()=>import('@/views/WaybillManager/WaybillDetailView.vue'),
          meta:{
            keepAliva:true,
            title:'运单详情',
            icon:'',
            hide:true,
            requireAuth:true
          }
        },
      ]
    },
    {
      path:'/addr',
      name:'address',
      component:null,
      meta:{
        keepAliva:true,
        title:'地址薄',
        icon:'',
        requireAuth:true
      },
      children:[
        {
          path:'/addr/list',
          name:'addr_list',
          component:()=>import('@/views/AddressManager/AddressListView.vue'),
          meta:{
            keepAliva:true,
            title:'地址列表',
            icon:'',
            requireAuth:true
          }  
        },
        {
          path:'/addr/add',
          name:'addr_add',
          component:()=>import('@/views/AddressManager/NewAddressView.vue'),
          meta:{
            keepAliva:true,
            title:'添加地址',
            icon:'',
            isCache:true,
            requireAuth:true
          }  
        },
        {
          path:'/addr/edit',
          name:'addr_edit',
          component:()=>import('@/views/AddressManager/NewAddressView.vue'),
          meta:{
            keepAliva:true,
            title:'修改地址',
            icon:'',
            isCache:true,
            hide:true,
            requireAuth:true
          }  
        }


      ]
    },
    {
      path:'/bind',
      name:'bind',
      component:()=>import('@/views/Binds/BindsView.vue'),
      meta:{
        keepAliva:true,
        title:'关系绑定',
        icon:'',
        requireAuth:true
      },
      noMenu:true 
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
      meta:{
        keepAliva:true,
        title: "关于",
        icon: ''
      },
      noMenu:true 
    } ,
    {
      path:'/:pathMatch(.*)*',
      redirect:'/'
    }  
  ]

  export default routerMaps;