
// axios二次封装
// yarn add axios-adapter-uniapp import axiosAdapterUniapp from 'axios-adapter-uniapp'
import { getToken,setToken } from "@/utils/auth";
import axios from "axios";
import config from '@/config'
import router from '../router'

// 小程序axios适配器
//import mpAdapter from "axios-miniprogram-adapter";
//axios.defaults.adapter = mpAdapter;
//import { toast, showConfirm, tansParams } from "@/utils/common";
//根据环境变量获取api地址
//let baseURL = process.env.config[process.env.UNI_SCRIPT].VITE_BASE_API;
let baseURL="http:"+config.baseUrl.svr
// let evnName = process.env.config[process.env.UNI_SCRIPT] 获取当前处于哪个开发环境
console.log("baseURL:", baseURL, "++++++++++++++++++++++++");
class HttpRequest {
  constructor() {
    this.baseURL = baseURL; // 从环境变量中获取api地址
    this.timeout = 300000;
  }
  mergeOptions(options) {
    return {
      baseURL,
      timeout: 300000,
      crossDomain: true,
      ...options,
    };
  }
 
  getInsideConfig(ContentTypeJson) {
		var headers = {};
    
    const token=getToken();
    if(token!=null && token!='')
    {
      headers.Authorization='Bearer '+token;
      //config.headers["Authorization"] = `Bearer `+token;
    }

		const config = {
			//baseURL: this.baseUrl,
			headers: headers
		}
		return config
	}

  request(options) {
    /*
		var ContentTypeJson = false; // json的类型传承

		if (options.dataType && options.dataType=='json') {//json方式传参
			ContentTypeJson = true;
		}else if(typeof options['data'] != 'undefined'){//form data 方式传参
			options['data'] = _qs.stringify(options['data']);
		}
    */

    const instance = axios.create();
    //options = Object.assign(options,this.getInsideConfig({}));
    this.setInterceptors(instance);
    const opts = this.mergeOptions(options);
    return instance(opts);
  }
  get(url, data = {}, outHeaders = {}) {
    // console.log(data, "data+++++++++++++");
    return this.request({
      dataType: "json",
      method: "get",
      url,
      params: { ...data }, // get参数可以直接展开
      headers: outHeaders,
    });
  }
  post(url, data = {}, outHeaders = {}) {
    // 请求体中 {}
    return this.request({
      method: "post",
      url,
      data, // post要求必须传入data属性
      headers: outHeaders,
    });
  }
  // 设置拦截器
  setInterceptors(instance) {
    // 请求拦截器
    instance.interceptors.request.use(config => {
      const noLoading = config.headers["NO-LOADING"];
      // 是否需要设置 token
      //const isToken = config.headers["ISTOKEN"] || true;
      //if (getToken() && isToken) {
      //  config.header["Authorization"] = `Bearer ${getToken()}`;
        //config.header["token"] = `${getToken()}`;
      //}
      const token=getToken()
      if(token!=null && token!='')
      {
        config.headers.Authorization='Bearer '+token;
        //config.headers["Authorization"] = `Bearer `+token;
      }

      if (!noLoading) {
        /*
        uni.showLoading({
          title: "加载中...",
        });
        */
      }
      /*
      config.headers = {
        ...config.headers,
      };
      */
      //console.log('config',config)
      return config;
    },error=>{
      console.log(error)
    });
    // 响应拦截器
    instance.interceptors.response.use(
      (res) => {
        const noLoading = res.config.headers["NO-LOADING"];
        if (!noLoading) {
          //uni.hideLoading();
        }
        let { data} = res;
        // console.log("请求获取data", data)
        if (data) {
          if(data.token && data.token.length>0)
            //uni.setStorageSync("token",data.token)
            setToken(data.token)
          if (data.code === 200) {
            //console.log('data=============', data)
            return Promise.resolve(data);
          } else {
            console.log(JSON.stringify(res))
            if(data.code===-1)
              {
                //this.$router.push({name:'result'});
                router.push({path:'/error',query:{error:data.message}});
              }
              else if(data.code===-201)
              {
                router.push({path:'/error',query:{error:data.message}});
              }
            /*
            showConfirm({
              content: data.msg,
              showCancel: false,
            }).then((res) => {
              /*               if (res.confirm) {
                store.dispatch("LogOut").then((res) => {
                  uni.reLaunch({ url: "/pages/login" });
                });
              } */
              /*
            });
            */
            return Promise.resolve(data);
          }
        }
      },
      (err) => {
        console.log("axios报错", err);
        //uni.hideLoading();
        return Promise.reject(err);
      }
    );
  }
}
export default new HttpRequest();
