<template>
  <div class="container">
    <div class="left" v-if="$route.meta.keepAliva">

      <Menu :theme="theme" @on-select="MenuSelect">
        <MenuSub :menus="menuList" ></MenuSub>
      </Menu>
      
    </div>
    <div class="right">
        <router-view v-slot="{Component}">
          <KeepAlive>
            <component :is="Component" :key="$route.name" v-if="$route.meta.isCache"/>
          </KeepAlive>
          <component :is="Component" :key="$route.name" v-if="!$route.meta.isCache"/>
        </router-view>

    </div>

  </div>
  
</template>

<script>
import MenuSub from '@c/MenuNav/MenuSub.vue'

  export default{
    components:{MenuSub},
    data(){
      return {
        showNav:false,
        theme:'dark'
      }
    },
    computed:{
      menuList(){
        return this.$store.getters.Menus;
      }
    },
    methods:{
      MenuSelect(name){
        //console.info(name+"==================")
      }
    }
  }
</script>

<style>

@import '@/assets/styles.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.container{
  display: flex;
  width: 1280px;
  margin: 0 auto;
  flex-direction: row;
  justify-content: center;
}

.left{
  flex: 10px;
  background-color: #515a6e;
  padding-top: 120px;
  padding-bottom: 200px;
}

.right{
  flex: 5;
}

</style>
