

var configObject={
    title:"TrunkRun",
    cookieExpires:1,
    useI18n: false,
    baseUrl: (() => {
		var urls = {
			//pro: '//hw.sayars.top:8090/api',
			//pro: '//172.18.0.4:8090/api',
			pro: '//localhost:8090/api',
			//dev: '//hw.sayars.top:8090/api',
			dev: '//localhost:8090/api',
			svr: null ,
			isTestServer:false
		};
		if (process.env.NODE_ENV != 'development') {//正式环境
			urls.isTestServer=false;
			urls.svr = urls.pro;
		} else {
			urls.isTestServer=true;
			urls.svr = urls.dev;
		}
		console.log('@API_BASE_URL:' + urls.svr);
		return urls;
	})(),
    /**
	 * @description 是否是测试模式
	 */
	inDebugMode: (function() {
		return (process.env.NODE_ENV == 'development');
	})(),
	/**
	 * @description 默认打开的首页的路由name值，默认为home
	 */
	homeName: 'root',//''case_d3',
	/**
	 * @description 需要加载的插件
	 */
	plugin: {
		'error-store': {
			showInHeader: true, // 设为false后不会在顶部显示错误日志徽标
			developmentOff: true // 设为true后在开发环境不会收集错误信息，方便开发中排查错误
		}
	},
	/**
	 * 错误信息
	 */
	errMsg: {
		axiosErr: '服务器异常请稍后重试',
	}
}

export default configObject;